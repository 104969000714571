import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GoogleLogin from './GoogleLogin';
import GoogleLogout from './GoogleLogout';
import { useAuth } from '../context/AuthContext';
import logo from '../logo.png';

function Navbar() {
    const { isLoggedIn, login, logout } = useAuth();
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleGoogleLogin = (responseData) => {
        console.log("User authenticated successfully:", responseData);
        login(responseData.user);
        setShowModal(false);
    };

    const handleGoogleLogout = () => {
        logout();
    };

    const handleMyCollectionsClick = (e) => {
        if (!isLoggedIn) {
            e.preventDefault();
            setShowTooltip(true);
        } else {
            navigate('/collections');
        }
    };

    return (
        <>
            <nav className="navbar navbar-expand-md custom-navbar navbar-dark bg-dark sticky-top position-relative">
                <div className="container">
                    <Link className="navbar-brand d-flex align-items-center" to="/">
                        <img
                            src={logo}
                            alt="App Logo"
                            style={{ width: '40px', height: 'auto', marginRight: '8px' }}
                        />
                        similto
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav navbar-center">
                            <li
                                className="nav-item nav-link-wrapper"
                                onMouseEnter={() => !isLoggedIn && setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            >
                                <Link
                                    className={`nav-link ${isLoggedIn ? '' : 'disabled-link'}`}
                                    to={isLoggedIn ? '/collections' : '#'}
                                    onClick={handleMyCollectionsClick}
                                >
                                    My Collections
                                </Link>
                                {!isLoggedIn && showTooltip && (
                                    <div className="tooltip">
                                        Login to interact with your collections
                                    </div>
                                )}
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto align-items-center">
                            <li className="nav-item google-login-wrapper">
                                {!isLoggedIn ? (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setShowModal(true)}
                                    >
                                        Log In
                                    </button>
                                ) : (
                                    <GoogleLogout onLogout={handleGoogleLogout} />
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {showModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-sm">
                        <div className="modal-content" style={{ background: '#2e2e2e', color: '#e0e0e0' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ color: '#ffd500' }}>Log In</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    style={{ filter: 'invert(1)' }}
                                    onClick={() => setShowModal(false)}
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <GoogleLogin onLoginSuccess={handleGoogleLogin} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Navbar;
