import axios from 'axios';

const backendAppUrl = process.env.REACT_APP_BACKEND_API_URL;

const api = axios.create({
    baseURL: backendAppUrl,
    withCredentials: true,
});

export async function getCurrentUser() {
    try {
        const response = await api.get('/auth/me');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const fetchSimilarProducts = async (url, country, language) => {
    try {
        const response = await api.get(
            `/search-by-url?url=${url}&country=${country}&language=${language}`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching similar products', error);
        throw error;
    }
};

export const searchByImage = async (imageFile, country, language) => {
    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('country', country);
    formData.append('language', language);

    try {
        const response = await api.post('/search-by-photo/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error searching by image:', error);
        throw error;
    }
};

export async function getUserCollections() {
    const response = await api.get('/collections/');
    return response.data;
}

export async function addItemToCollection(collectionId, product) {
    const response = await api.post(`/collections/${collectionId}/items`, {
        title: product.title,
        link: product.link,
        source: product.source,
        price: product.price ? product.price.extracted_value : null,
        thumbnail: product.thumbnail || null,
    });
    return response.data;
}

export async function createNewCollection(name) {
    const response = await api.post('/collections/', { name });
    return response.data;
}

export default api;
