import React, { createContext, useState, useEffect } from "react";

export const LanguageCountryContext = createContext();

const backendAppUrl = process.env.REACT_APP_BACKEND_API_URL;

export const LanguageCountryProvider = ({ children }) => {
    const [language, setLanguage] = useState("en");
    const [country, setCountry] = useState("US");
    const [currency, setCurrency] = useState("$");

    useEffect(() => {
        // Fetch country and language from backend
        fetch(backendAppUrl + "/geolocation")  // Assuming backend runs on localhost:8000
            .then((response) => response.json())
            .then((data) => {
                if (data.country_code) {
                    setCountry(data.country_code);
                }
                if (data.language) {
                    setLanguage(data.language);
                }
                if (data.currency) {
                    setCurrency(data.currency);
                }
            })
            .catch((error) => console.error("Error fetching geolocation data.", error));
    }, []);

    return (
        <LanguageCountryContext.Provider value={{ language, country, currency }}>
            {children}
        </LanguageCountryContext.Provider>
    );
};
